import React from 'react'
import kabilan from './image/pic1.jpg';
import mahesh from './image/pic4.jpg';
import pravin from './image/pic2.jpg';
import praveena from './image/pic3.jpg';
import Jebaraj from './image/pic5.jpg';
import './team.css'

const Team = () => {
    return (
        <div>
            <section className='team'>
                <div className='team-cont'>
                    <h1 className='team-title'>Meet Our Troops...</h1>
                    <div className='team-row'>
                        <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={pravin}></img>
                                </div>
                                <h1 className='team-name'>Pravin Kumar P</h1>
                                <p className='team-role'>MD & CEO<br></br>VETRI Companies</p>
                            </div>
                        </div>
                        <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={Jebaraj}></img>
                                </div>
                                <h1 className='team-name'>Jebaraj S</h1>
                                <p className='team-role'>VIS Business Manager</p>
                            </div>
                        </div>
                        <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={praveena}></img>
                                </div>
                                <h1 className='team-name'>Praveena S</h1>
                                <p className='team-role'>VIS Project Lead</p>
                            </div>
                        </div>
                        <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={mahesh}></img>
                                </div>
                                <h1 className='team-name'>Maheshwaran S</h1>
                                <p className='team-role'>VIS Project Lead</p>
                            </div>
                        </div>
                        <div className='team-col'>
                            <div className='team-card'>
                                <div className='team-img'>
                                    <img src={kabilan}></img>
                                </div>
                                <h1 className='team-name'>Kabilan T</h1>
                                <p className='team-role'>VIS Project Lead</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Team;
